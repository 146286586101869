import { Injectable } from "@angular/core";
import { values } from "core-js/core/dict";
import { BehaviorSubject } from "rxjs";
import { MediaMatcherService } from "./mediamatcher.service";
export interface ITemplateConfig {
    layout: {
        variant: string;                   // options: Dark, Light & Transparent
        autoMode: boolean;
        menuPosition: string;              // options: Side, Top (Note: Use 'Side' for Vertical Menu & 'Top' for Horizontal Menu )
        customizer: {
            hidden: boolean;               // options: true, false
        };
        navbar: {
            type: string;                     // options: Static & Fixed
        }
        sidebar: { //Options for Vertical Side menu
            defaultCollapsed: boolean;      // Default state  - (colapsed driven by media size)
            collapsed: boolean;             // options: true, false
            size: string;                   // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
            backgroundColor: string;        // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

            /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
              bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
            */
            backgroundImage: boolean;        // Options: true, false | Set true to show background image
            backgroundImageURL: string;
        }
    };
}

const CONFIG_VAR = "C3_layout_config";

@Injectable({
    providedIn: "root"
})
export class ConfigService {

    public darkMode: boolean = false;
    private systemDarkMode: boolean = false;
    darkModeSubject = new BehaviorSubject<boolean>(this.darkMode);
    get darkMode$() {
        return this.darkModeSubject.asObservable();
    }
    public templateConf: ITemplateConfig = this.loadConfig() //this.setConfigValue();
    templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
    templateConf$ = this.templateConfSubject.asObservable();

    private onMediaChange = new BehaviorSubject<{ matchingAliases: string[]; matchingRules: any; }>({
        matchingAliases: [],
        matchingRules: {}
    });


    constructor(
        mediaMatcherService: MediaMatcherService
    ) {
        mediaMatcherService.onMediaChange$.subscribe((changes) =>{
            //console.log("Matcher changed:",changes);
            changes.matchingAliases.forEach(alias => {
                switch(alias){
                    case "dark-mode":
                        this.systemDarkMode = this.darkMode = true;                        
                        break;
                    case "normal-mode":
                        this.systemDarkMode = this.darkMode = false;
                        break;
                }
            });
            this.applyAutoVariant();
        });
        //console.log("Initial CFG (darkmode = "+(this.darkMode)+")",this.templateConf);
    }

    applyAutoVariant(){
        if (this.templateConf.layout.autoMode){
            this.darkMode = this.systemDarkMode;
            let autoVariant = this.darkMode ? "Dark" : "Light";
            if (this.templateConf.layout.variant != autoVariant){
                console.log('CONFIG Service: Setting autoVariant -> '+autoVariant);
                this.templateConf.layout.variant = autoVariant;
                this.templateConfSubject.next(this.templateConf);
            }
        } else {
            let darkMode = (this.templateConf.layout.variant != "Light");
            if (darkMode != this.darkMode){
                this.darkMode = darkMode;
            }
        }
        if (this.darkMode != this.darkModeSubject.getValue()){
            this.darkModeSubject.next(this.darkMode);
        }
        // console.log("DarkMode SVC after apply:"+(this.darkMode?"YES":"NO"));
        // console.log("DarkMode SUB after apply:"+(this.darkModeSubject.getValue()?"YES":"NO"));
    }

    setConfigValue() {
        return this.templateConf = {
            layout: {
                variant: "Transparent", //Dark,Light,Transparent
                //variant: "Dark", //Dark,Light,Transparent,
                autoMode: false,
                menuPosition: "Top", //Side | Top
                customizer: {
                    hidden: true
                },
                navbar: {
                    type: 'Static'
                },
                sidebar: {
                    defaultCollapsed: false,
                    collapsed: false,
                    size: "sidebar-md",
                    //backgroundColor: "man-of-steel",
                    //backgroundColor: "bg-glass-1",
                    backgroundColor: "bg-glass-portage",
                    backgroundImage: true,
                    backgroundImageURL: "assets/img/sidebar-bg/01.jpg"
                }
            }
        };
    }


    // Default configurations for Light layout. Please check *customizer.service.ts* for different colors and bg images options
    getDefaultConfigVals(): ITemplateConfig {
        return {
            layout: {
                variant: "Light",
                autoMode: true,
                menuPosition: "Side",                
                customizer: {
                    hidden: true
                },
                navbar: {
                    type: 'Static'
                },
                sidebar: {
                    defaultCollapsed: false,
                    collapsed: false,
                    size: "sidebar-md",
                    backgroundColor: "ibiza-sunset", // "man-of-steel",
                    backgroundImage: true,
                    backgroundImageURL: "assets/img/sidebar-bg/01.jpg"
                }
            }
        };
    }
    // Default configurations for Dark layout. Please check *customizer.service.ts* for different colors and bg images options

    // setConfigValue() {
    //   return this.templateConf = {
    //     layout: {
    //       variant: "Dark",
    //       menuPosition: "Side",
    //       customizer: {
    //         hidden: true
    //       },
    //       navbar: {
    //         type: 'Static'
    //       },
    //       sidebar: {
    //         collapsed: false,
    //         size: "sidebar-md",
    //         backgroundColor: "black",
    //         backgroundImage: true,
    //         backgroundImageURL: "assets/img/sidebar-bg/01.jpg"
    //       }
    //     }
    //   };
    // }

    // Default configurations for Transparent layout. Please check *customizer.service.ts* for different colors and bg images options

    // setConfigValue() {
    //   return this.templateConf = {
    //     layout: {
    //       variant: "Transparent",
    //       menuPosition: "Side",
    //       customizer: {
    //         hidden: true
    //       },
    //       navbar: {
    //         type: 'Static'
    //       },
    //       sidebar: {
    //         collapsed: false,
    //         size: "sidebar-md",
    //         backgroundColor: "bg-glass-1",
    //         backgroundImage: true,
    //         backgroundImageURL: ""
    //       }
    //     }
    //   };
    // }


    applyTemplateConfigChange(tempConfig: ITemplateConfig, saveConfig = true) {
        let sc = tempConfig.layout.sidebar.collapsed ? "collapsed" : "no";
        let dsc = tempConfig.layout.sidebar.defaultCollapsed ? "collapsed" : "no";
        //console.warn(`TemplateConfigChange - sidebar current=${sc}, default=${dsc}`);
        if (saveConfig) {
            this.saveConfig(tempConfig);
        }
        this.templateConf = Object.assign(this.templateConf, tempConfig);
        this.applyAutoVariant();
        this.templateConfSubject.next(this.templateConf);
        this.darkModeSubject.next(this.darkMode);
    }

    saveConfig(currentConfig: ITemplateConfig | null) {
        let jsonConfig = JSON.stringify(currentConfig);
        if (jsonConfig) {
            localStorage.setItem(CONFIG_VAR, jsonConfig);
        }
        else localStorage.removeItem(CONFIG_VAR);
    }

    loadConfig(): ITemplateConfig {
        this.templateConf = this.getDefaultConfigVals();
        let savedConfig = localStorage.getItem(CONFIG_VAR);
        if (savedConfig) {
            let objConfig;
            objConfig = JSON.parse(savedConfig);
            if (objConfig.layout) {
                this.templateConf = Object.assign(this.templateConf, objConfig);
            }
        }
        return this.templateConf;
    }

    resetConfig() {
        localStorage.removeItem(CONFIG_VAR);
        this.templateConf = this.getDefaultConfigVals();
        this.applyAutoVariant();
        this.templateConfSubject.next(this.templateConf);
        this.darkModeSubject.next(this.darkMode);
    }

}
