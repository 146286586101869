import { Injectable,NgModule, SkipSelf, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import {debounceTime, delay, switchMap, tap, map} from 'rxjs/operators';
import { EndpointService } from './endpoint.service';
import * as moment from 'moment-timezone';
import {CalendarEvent} from 'angular-calendar';
import { HttpParams } from '@angular/common/http';


// Calendar colors
const colors: any = {
    red: {
        primary: '#ff30a780',
        secondary: '#ff30a780',
        //secondary: '#FAE3E380'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#e3bc0840',
        //    secondary: '#FDF1BA'
    },
    purple: {
        primary: '#ee3079',
        secondary: '#ee307940',
    },
    orange: {
        primary: '#ff9602',
        secondary: '#ff960240'
    },
    green: {
        primary: '#33d84c',
        secondary: '#33d84c40'
    }

};

export interface C3Calendar {
    calendarId: any,
    calendarName: string,
    calendarUid: any,
    calendarColor: string,
    calendarClass: string,
    events: any[]
}

export interface C3EventOptions {
    actions?: any;
    draggable?: boolean;
    resizable?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CalendarService {


    // Update interval in milis (1min)
    private timeLineUpdateInterval = 60*1000;
    private _timeLineTimer: any;
    public timeLineTimer$ = new Subject<void>();

    private _workCalendars: BehaviorSubject<C3Calendar[]> = new BehaviorSubject<C3Calendar[]>([]);
    private _dailyCalendars: BehaviorSubject<C3Calendar[]> = new BehaviorSubject<C3Calendar[]>([]);
    private _userCalendars: BehaviorSubject<C3Calendar[]> = new BehaviorSubject<C3Calendar[]>([]);

    private _workCalendarsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _dailyCalendarsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _userCalendarsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    private _wcDateFrom = moment();
    private _wcDateTo = moment();

    private _loadWorkCalendars$ = new Subject<void>();

    get workCalendars$(): Observable<C3Calendar[]>{
        return this._workCalendars.asObservable();
    }
    get workCalendarsLoading$(): Observable<boolean>{
        return this._workCalendarsLoading.asObservable();
    }

    get dailyCalendars$(): Observable<C3Calendar[]>{
        return this._dailyCalendars.asObservable();
    }
    get dailyCalendarsLoading$(): Observable<boolean>{
        return this._dailyCalendarsLoading.asObservable();
    }

    get userCalendars$(): Observable<C3Calendar[]>{
        return this._userCalendars.asObservable();
    }
    get userCalendarsLoading$(): Observable<boolean>{
        return this._userCalendarsLoading.asObservable();
    }

    events: C3Calendar[] = [];
    events$: Observable<C3Calendar[]> = new Observable();


    constructor(
        private _endpointService: EndpointService,
        @Optional() @SkipSelf() parentModule?:CalendarService
      )
      {
        if(parentModule){
          throw new Error(
            'Socket Service is already loaded. Import it in the AppModule only');
        }
        this._timeLineEvent();
      }
    
    // Emit event for time line (red) move
    private _timeLineEvent(){
        if (this._timeLineTimer){
            clearInterval(this._timeLineTimer);
            this._timeLineTimer=null;
        }
        if (this.timeLineTimer$.observers.length){
            this.timeLineTimer$.next();
        }
        let date = new Date();

        let tmo=(60*date.getMinutes()+date.getSeconds())*1000+date.getMilliseconds();
        let next=this.timeLineUpdateInterval - (tmo % this.timeLineUpdateInterval);
        console.debug(`CALENDAR Service Timeline - ${this.timeLineTimer$.observers.length} observers, next event in ${next/1000}s`);        

        this._timeLineTimer = setInterval(()=>{
            this._timeLineEvent();
        },next);
    }


    private _workCalendarLoader(): Observable<void>{
        let dtFrom = "20210101T000000Z";
        let dtTo="20210630T000000Z";
        const params = new HttpParams()
        .set('dtFrom',dtFrom)
        .set('dtTo',dtTo)
        .set('calendars',[2,3,4].join(','));

        console.log("Getting calendars ...");
        return this._endpointService.callApiMethod('/calendar/events',{params},false)
        .pipe(
            map(({ calendars, dateFrom, dateTo }: { calendars: C3Calendar[], dateFrom: string | null, dateTo: string | null }) => {
                console.info("Calendar data arrived");
                this._wcDateFrom = moment(dateFrom);
                this._wcDateTo   = moment(dateTo);
                this._workCalendars.next(calendars);
            })
        );

    }

    loadWorkCalendarData(){
        console.log("Setting WorkCalendar Loader to \"processing\"");
        this._workCalendarsLoading.next(true);
        this._workCalendarLoader().subscribe(()=>{
            console.log("Setting WorkCalendar Loader to \"idle\"");
            this._workCalendarsLoading.next(false);
        });
    }

    getCalendarEvents(options: C3EventOptions = {
        draggable: false,
        resizable: false,
        actions: null
    } ) : CalendarEvent[]{
        let events: CalendarEvent[] = [];
        this._workCalendars.getValue().map((calendar: C3Calendar) => {
            let cColor = calendar.calendarColor ? calendar.calendarColor : "orange";
            let cClass = calendar.calendarClass ? calendar.calendarClass : "orangeClass";
            const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
            calendar.events.map((c3event: any) => {

                let dStart = moment.tz(c3event.dtStart.date, c3event.dtStart.timezone).format();
                let dEnd = moment.tz(c3event.dtEnd.date, c3event.dtEnd.timezone).format();
                if (c3event.allDayEvent) {                    
                    dStart = moment.tz(c3event.dtStart.date, localTZ).format();
                    dEnd = moment.tz(c3event.dtEnd.date, localTZ).subtract(1,'millisecond').format();
                }
                let cEvent: CalendarEvent = {
                    title: c3event.summary,
                    start: new Date(dStart),
                    end: new Date(dEnd),
                    allDay: c3event.allDayEvent,
                    resizable: {
                        beforeStart: options.resizable,
                        afterEnd: options.resizable
                    },
                    draggable: options.draggable,
                    cssClass: cClass,
                    color: colors[cColor],
                    actions: options.actions,
                    meta: {
                        c3eventId: c3event.c3eventId,
                        description: c3event.description,
                        eventUid: c3event.uid,
                        location: c3event.location,
                        geo: c3event.geo
                    }
                }
                if (c3event.allDayEvent) {
                    console.log(c3event, cEvent);
                }
                events.push(cEvent);

            });
        });
        return events;
    }
}
  