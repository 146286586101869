import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';


import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarDateFormatter,
  CalendarEventTitleFormatter,
  DAYS_OF_WEEK,
  CalendarView
} from 'angular-calendar';
import { CustomDateFormatter } from '../formaters/custom-date-formatter.provider';
import { CustomEventTitleFormatter } from '../formaters/custom-event-title-formatter.provider';
import { Subject } from 'rxjs';
import { CalendarService, C3Calendar } from '../../shared/services/calendar.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },    
  ],  

})
export class NotificationSidebarComponent implements OnInit, OnDestroy {


  // calendar
  dayStartHour  = 15;
  dayEndHour    = 19;
  locale: string = 'cs';
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  refreshCalendar: Subject<any> = new Subject();


  layoutSub: Subscription = new Subscription();
  timeLineSub: Subscription = new Subscription();
  isOpen = false;

  ngOnInit() {

  }

  constructor(private layoutService: LayoutService,
    private _calendarService: CalendarService,
    ) {

    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
      open => {
        if (open){
          this.updateCalendarRange()
          this.refreshCalendar.next();
          
          this.timeLineSub = this._calendarService.timeLineTimer$
          .subscribe(() => {
              console.log("notificstion sidebar - update timeline");
              this.updateCalendarRange()
              this.refreshCalendar.next();
  
          });
  
        } else {
          if (this.timeLineSub) {
            this.timeLineSub.unsubscribe();
          }
        }
        this.isOpen = open;
      });
  }

  updateCalendarRange(){
    let stH = new Date().getHours()-2;
    if (stH>20) stH = 20;
    if(stH<0) stH= 0;
    if (this.dayStartHour != stH){
      this.dayStartHour = stH;
      this.dayEndHour = stH+4;
  
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

}
