import { Injectable,NgModule, SkipSelf, Optional } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';
import { User } from '../data/user.interface';
import { AuthService } from '../auth/auth.service'
/*
// odebrano z angular.json
            "scripts": [
              "src/assets/vendor/pace/pace.min.js"
            ]
*/
const MAX_CONNECT_ATTEMPTS = 4;

export interface IMuser  {
    userID: string,
    username: string,
    avatarUrl: string,
    connected?: boolean,
    hasNeverMessages?: boolean,
    hasNewMessages?: boolean,
    messages: IMmesage[],
    isActiveChat?: boolean,
    status: string,
    self: string
    lastChatTime?: string;
    isPinnedUser?: boolean;
    isMuted?: boolean;
    unreadMessageCount?: string;
    lastChatMessage?: string;
    newMessage?: string;
}

export interface IMmesage {
    fromSelf: boolean,
    content: any
}

export interface IMuserData {

}


@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  private socket: Socket = io({
    autoConnect: false
  });  
  constructor(
    private _endpointService: EndpointService,
    private _authService: AuthService,
    @Optional() @SkipSelf() parentModule?:SocketioService
  )
  {
    if(parentModule){
      throw new Error(
        'Socket Service is already loaded. Import it in the AppModule only');
    }
    _authService.authenticated$.subscribe((authenticated)=>{
      if (authenticated){
        this.setupSocketConnection(
          _authService.accessToken,
          _authService.userData
        );
      } else {
        this.disconnect();
      }
    });
  }


  //private sessionID: string = localStorage.getItem(WSSSID);
  private userID: string = "";
  private userName: string = "";
  private _users: IMuser[] = [];
  private _userData :User|null = null;
  private _connectErrorsCount = 0;
//  private _connected = false;

  private auth = {
      token: "",
      userData: {},
  }

  set userData(data) {
    this._userData = data;
  }

  get userData(){
    return this._userData;
  }

  get users(){
    return this._users;
  }

  private _userChats: BehaviorSubject<IMuser[]> = new BehaviorSubject<IMuser[]>([]);


  get userChats$(): Observable<IMuser[]> {
    return this._userChats.asObservable();
  }
  
  private storeUsers(users: IMuser[]): Observable<IMuser[]> {
    this._userChats.next(users);
    return this.userChats$;
  }  

  private addUser(user: IMuser){
    this._userChats.next([...this._userChats.getValue(), user]);
  }

  private _connectError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get connectError$(): Observable<boolean> {
    return this._connectError.asObservable();
  }

  private _connected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get connected$(): Observable<boolean> {
    return this._connected.asObservable();
  }

  private setConnected(connected: boolean): Observable<boolean> {
    this._connected.next(connected);
    return this.connected$;
  }

  setupSocketConnection(token:string|null = null, userData:User|null = null) : boolean{

    if (this.socket){
      this.socket.offAny();
      this.socket.disconnect();
    }

    //let socketHost = "http://localhost:3000";
    let socketHost = this._endpointService.imEndpoint;
    ;
    if (token  && token.length > 0) {
      this.auth.token = token;
    }
    if (userData != undefined){
      this.userData = userData;      
    }

    if (!this.auth.token || this.auth.token.length ==0 ){
      console.warn("IM: Token not available");
      return false;
    }
    if (this.userData == undefined){
      console.warn("IM: UserData not available");
      return false;
    } else {

        this.auth.userData = this.userData;
    }

    
    this.socket = io(socketHost,{
      reconnectionDelayMax: 10000,
      auth: this.auth,
      autoConnect: true
    });

    this.socket.onAny((event, ...args) => {
      console.debug("IM EVENT", event, args);
    });

    this.socket.on("session", ({ sessionID, userID, username }) => {

      // save the ID of the user
      this.userID = userID;
      this.userName = username;
    });

    this.socket.on("connect_error", (err) => {

      console.error("WS EROR: " + err.message);
      if (err.message === "invalid_session") {
        this.setupSocketConnection();

      } else if (err.message === "invalid_token"){
        this.disconnect();
      } else if (this._connected.getValue()){
        this.markDisconnected()    
      }
      if (!this._connected.getValue()){
        this._connectErrorsCount = this._connectErrorsCount+1;
        if (this._connectErrorsCount >= MAX_CONNECT_ATTEMPTS && !this._connectError.getValue()){
          this._connectError.next(true);
        }
      } else {
        this.setConnected(false);
      }


    });

    this.socket.on("connect", () => {
      console.debug("Messaging subsystem connected");
      this._connectErrorsCount = 0;
      this._connectError.next(false);
      this.setConnected(true);
      this.users.forEach((user) => {
        if (user.self) {
          user.connected = true;
          user.status="online";
        }
      });
      this.storeUsers(this.users);      
    });

    this.socket.on("disconnect", () => {
      console.log("disconnect");
      this.markDisconnected();
    });


    this.socket.on("users", (users) => {
      users.forEach((user: any) => {
        user.messages.forEach((message: any) => {
          message.fromSelf = message.from === this.userID;
        });
        let userExists=false;
        for (let i = 0; i < this.users.length; i++) {
          const existingUser = this.users[i];
          if (existingUser.userID === user.userID) {
            existingUser.connected = user.connected;
            existingUser.status = user.connected?"online":"offline";  
            existingUser.messages = user.messages;
            //console.warn("USERS - return finish for: "+user.username);
            userExists = true;
            break;
          }
        }
        if (!userExists){
            user.self = user.userID === this.userID;
            user.avatarUrl = user.userData.avatar;
            user.status = user.connected?"online":"offline";  
            //initReactiveProperties(user);
            this.users.push(user);
        }
      });
      // put the current user first, and sort by username
      this.users.sort((a, b) => {
        if (a.self) return -1;
        if (b.self) return 1;
        if (a.username < b.username) return -1;
        return a.username > b.username ? 1 : 0;
      });
      // TEMP
      this.storeUsers(this.users);
    });

    this.socket.on("user connected", (user) => {
      for (let i = 0; i < this.users.length; i++) {
        const existingUser = this.users[i];
        if (existingUser.userID === user.userID) {
          existingUser.connected = true;
          existingUser.avatarUrl = user.userData.avatar;
          existingUser.status="online";
          this.storeUsers(this.users);
          return;
        }
      }
      //initReactiveProperties(user);
      user.self = user.userID === this.userID;
      user.avatarUrl = user.userData.avatar;
      user.status = user.connected?"online":"offline";
      this.users.push(user);
      this.storeUsers(this.users);
    });

    this.socket.on("user disconnected", (id) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        if (user.userID === id) {
          user.connected = false;
          user.status="offline";
          this.storeUsers(this.users);
          break;
        }
      }
    });

    this.socket.on("private message", ({ content, from, to }) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        const fromSelf = this.userID === from;
        if (user.userID === (fromSelf ? to : from)) {
          user.messages.push({
            content,
            fromSelf,
          });
          user.hasNewMessages = true;
          break;
        }
      }
    });

    return true;
  }

  markDisconnected(){
    console.warn("Marking users disconnected");
    this.setConnected(false);
    this.users.forEach((user) => {
        user.connected = false;
        user.status = "offline";
    });
    this.storeUsers(this.users);

  }

  connect(){
      if (this.socket.connected){
        return; //Already connected
      }
      console.log("IM: connecting");    
      this.socket.connect();

  }

  disconnect(){
    if (this.socket){
      this.socket.off("session");
      this.socket.off("connect");
      this.socket.off("disconnect");
      this.socket.off("users");
      this.socket.off("user connected");
      this.socket.off("user disconnected");
      this.socket.off("private message");  
    }
    this.socket.disconnect();
    console.log("IM: disconnected");    
  }


  public getMessages = () => {
    return new Observable((observer: any) => {
        this.socket.on('new-message', (message) => {
            console.log("IM message:");
            console.log(message);
            observer.next(message);
        });
    });
}  
}
