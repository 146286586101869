import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404AppComponent } from './error404.component';


const routes: Routes = [
  {
    path: '',
    component: Error404AppComponent,
    data: {
      title: 'Error'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class Error404RoutingModule { }
