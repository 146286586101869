import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { Error404RoutingModule } from "./error404-routing.module";

import { Error404AppComponent } from "./error404.component";


@NgModule({
  imports: [
    CommonModule,
    Error404RoutingModule
  ],
  exports: [],
  declarations: [
    Error404AppComponent
  ],
  providers: [],
})
export class Error404Module { }
