import { Injectable } from "@angular/core";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from "rxjs";



// if (window.matchMedia && 
//   window.matchMedia('(prefers-color-scheme: dark)').matches) {
//   img.style.filter="invert(100%)";
// }
export const mediaBreakpoints = {
    'xs': '(min-width:  0) and (max-width:  599px)',
    'sm': '(min-width:  600px) and (max-width:  959px)',
    'md': '(min-width:  960px) and (max-width:  1279px)',
    'lg': '(min-width:  1280px) and (max-width:  1439px)',
    'xl': '(min-width:  1440px)',
    'lt-md': '(max-width:  959px)',
    'lt-lg': '(max-width:  1279px)',
    'lt-xl': '(max-width:  1439px)',
    'gt-xs': '(min-width:  600px)',
    'gt-sm': '(min-width:  960px)',
    'gt-md': '(min-width:  1280px)',
    'dark-mode': '(prefers-color-scheme: dark)',
    'normal-mode': '(prefers-color-scheme: light)'
};


@Injectable({
  providedIn: "root"
})
export class MediaMatcherService {

  private onMediaChange=new BehaviorSubject<{ matchingAliases: string[]; matchingRules: any; }>({
    matchingAliases: [],
    matchingRules: {}
  });

  get onMediaChange$(){      
    return this.onMediaChange.asObservable()
  }

  constructor(
    private breakpointObserver: BreakpointObserver    
  ) {
      // Initialize
      this.initBreakpointObserver();
      //console.log("MediaMatcher service started");
  }

  
   /**
     * Initialize
     *
     * @private
     */
    private initBreakpointObserver(): void
    {
        // Subscribe to the breakpoint observer
        //this.breakpointObserver.observe(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        this.breakpointObserver.observe(Object.values(mediaBreakpoints))        
            .subscribe((state) => {

                const matchingAliases : any = [];
                const matchingRules: any = {};

                // If there are no matching rules, execute the observable and bail
                if ( !state.matches )
                {
                    this.onMediaChange.next({
                        matchingAliases,
                        matchingRules
                    });

                    return;
                }

                // Go through the breakpoints and find the ones that match
                for ( const [query, matches] of Object.entries(state.breakpoints) )
                {
                    if ( !matches )
                    {
                        continue;
                    }
                    //console.warn(`Breakpoint match: ${query} - ${matches?"yes":"no"}`);
                    // Get the alias of the matching query
                    const alias :any = (Object.keys(mediaBreakpoints) as Array<keyof typeof mediaBreakpoints> ).find(key => query === mediaBreakpoints[key]);

                    // // Prepare the observable values
                    matchingAliases.push(alias);
                    matchingRules[alias]  = query;
                }

                // Execute the observable
                this.onMediaChange.next({
                    matchingAliases,
                    matchingRules
                });
            });
    }

  /**
   * On media query change
   *
   * @param query
   */
    onMediaQueryChange$(query: string | string[]): Observable<BreakpointState>
    {
        return this.breakpointObserver.observe(query);
    }  
}
