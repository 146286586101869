import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this._check();

  }

  private _check(): Observable<boolean>{
    return this._authService.isAuthenticated()
    .pipe(
        switchMap((authenticated) => {          
          console.log("GUARD NOAUTH")
            // If the user is authenticated...
            if ( authenticated )
            {
                // Redirect to the root
                this._router.navigate(['']);

                // Prevent the access
                return of(false);
            }

            // Allow the access
            return of(true);
        })
    );
  }

}
