import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndpointService } from './endpoint.service';
import { HttpParams } from '@angular/common/http';
import { query } from '@angular/animations';
import { isThisTypeNode } from 'typescript';

export interface IEtkResult {
    resultID: number;
    parentResultId?: number;
    type: number,
    provider: string,
    query?: string,
    objectId?: number,
    page?: number,
    searchResult?: any,
    objectResult?: any,
    activeTab?: number,
    activeCG?: number
}

@Injectable({
    providedIn: 'root'
})
export class EtkService {

    private _provider = "junkers";
    private _resultID = 0;
    private _stepID = 0;
    private historyResults: IEtkResult[] = [];
    private stepPath: number[] = [];

    private activeResult: IEtkResult = { resultID:0, type: 0, provider: this._provider};
    private _activeResult: BehaviorSubject<IEtkResult> = new BehaviorSubject<IEtkResult>(this.activeResult);

    private _etkSearching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get etkResult$(): Observable<IEtkResult> {
        return this._activeResult.asObservable();
    }

    get etkSearching$(): Observable<boolean>{
        return this._etkSearching.asObservable();
    }



    constructor(
        private _endpointService: EndpointService,
        @Optional() @SkipSelf() parentModule?: EtkService
    ) {
        if (parentModule) {
            throw new Error(
                'ETK Service is already loaded. Import it in the AppModule only');
        }
        this.resetCache();
    }


    resetCache(){
        this._provider = "junkers";
        this._resultID = 0;
        this._stepID = 0;
        this.historyResults = [];
        this.stepPath = [];
        this.activeResult = { resultID:0, type: 0, provider: this._provider};
        this._activeResult.next(this.activeResult);
        this._etkSearching.next(false);
    }
    

    private _etkSearch(searchQuery: string, pageno: number = 1, provider: string =""): Observable<void>{
        console.log("ETK search started");
        const params = new HttpParams()
            .set('q',searchQuery)
            .set('p',String(pageno));
        if (!provider.length) {
            provider = this._provider;
        }
        //return this._endpointService.callApiMethod('/etk/search/'+provider+'?q='+searchQuery)
        return this._endpointService.callApiMethod('/etk/search/'+provider,{params})
        .pipe(
            map((result: any) => {
                console.info("ETK data returned");
                this._resultID++;
                let parentID=this.activeResult.resultID;
                this.activeResult = {
                    resultID: this._resultID,
                    parentResultId: parentID,
                    type: 1,
                    provider,
                    query: searchQuery,
                    page: pageno,
                    searchResult: result
                }
                console.log(this.activeResult);
                this.createNavigation();
                this.historyResults.push(this.activeResult);
                this._activeResult.next(this.activeResult);
            })
        );

    }

    private _etkObject(objectId: number, provider: string =""): Observable<void>{
        console.log("ETK search started");
        if (!provider.length) {
            provider = this._provider;
        }
        return this._endpointService.callApiMethod('/etk/detail/'+provider+'/'+objectId)
        .pipe(
            map((result: any) => {
                console.info("ETK data returned");
                this._resultID++;
                let parentID=this.activeResult.resultID;
                this.activeResult = {
                    resultID: this._resultID,
                    parentResultId: parentID,
                    type: 2,
                    provider,
                    objectId,
                    objectResult: result
                }
                console.log(this.activeResult);
                this.createNavigation();                
                this.historyResults.push(this.activeResult);
                this._activeResult.next(this.activeResult);
            })
        );

    }

    searchByQuery(searchQuery: string, pageno: number = 1, provider: string =""){
        if (!provider.length) {
            provider = this._provider;
        }
        let foundInCache = false;
        this._etkSearching.next(true);
        this.historyResults.map((r)=>{
            if (
                r.type ==1 
                && r.query == searchQuery
                && r.provider == provider
                && r.page == pageno
            ){
                console.log(`ETK QUERY ${searchQuery} found in cache`,r);
                foundInCache = true;
                this.activeResult = r;
                this.createNavigation();
                this._activeResult.next(this.activeResult);
                this._etkSearching.next(false);
                return;
            }
        });
        if (foundInCache){
            return;
        }
        let s = this._etkSearch(searchQuery, pageno, provider).subscribe(()=>{
            this._etkSearching.next(false);
            s.unsubscribe();
        });
    }

    getObject(objectId:number, provider:string=""){
        if (!provider.length) {
            provider = this._provider;
        }
        let foundInCache = false;
        this._etkSearching.next(true);
        this.historyResults.map((r)=>{
            if (
                r.type ==2 
                && r.objectId == objectId
                && r.provider == provider
            ){
                console.log(`ETK OBJECT ${objectId} found in cache`,r);
                foundInCache = true;
                this.activeResult = r;
                this.createNavigation();
                this._activeResult.next(this.activeResult);
                this._etkSearching.next(false);
                return;
            }
        });
        if (foundInCache){
            return;
        }
        let s = this._etkObject(objectId,provider).subscribe(()=>{
            this._etkSearching.next(false);
            s.unsubscribe();
        });
    }

    getCahcedResult(id: number){
        this._etkSearching.next(true);
        this.historyResults.map((r)=>{
            if (r.resultID == id){
                console.log(`ETK cached result ${id} found (${r.resultID})`,r);
                this.activeResult = r;
                this._activeResult.next(this.activeResult);
                return;
            }
        });
        this._etkSearching.next(false);
    }

    getLastResult(): IEtkResult{
        return this.activeResult;
    }

    hasPreviousStep(): boolean{
        return this._stepID>1;
    }
    hasNextStep(): boolean {
        return this._stepID<this.stepPath.length;
    }

    getPreviousResult(){
        this._etkSearching.next(true);
        if (this.hasPreviousStep() && this.stepPath.length >= this._stepID){
            this._stepID--;
            let cacheId = this.stepPath[this._stepID-1];
            console.log("Previous ObjID = "+cacheId);
            this.getCahcedResult(cacheId);
        } else {
            console.log("no previous");
        }
        this._etkSearching.next(false);
    }
    getNextResult(){
        this._etkSearching.next(true);
        if (this.hasNextStep() && this.stepPath.length > this._stepID){
            this._stepID++;
            let cacheId = this.stepPath[this._stepID-1];
            console.log("Next ObjID = "+cacheId);
            this.getCahcedResult(cacheId);
        } else {
            console.log("no next");
        }
        this._etkSearching.next(false);
    }

    private createNavigation(){
        if (this._stepID>0 && this._stepID<=this.stepPath.length){
            this.stepPath.splice(this._stepID);
        }
        this._stepID++
        this.stepPath.push(this.activeResult.resultID);
        //console.warn("History:",this.stepPath);
    }
}
