import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { dateInputToDateAdapter } from '@rschedule/core';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CalendarEvent): string {
    return this.formatLineEvent(event);
  }

  week(event: CalendarEvent): string {
    return this.formatBoxEvent(event);
  }

  day(event: CalendarEvent): string {
    return this.formatBoxEvent(event);
  }

  weekTooltip(event: CalendarEvent): string {
    let tt = `<div class="text-left">
    <b>${event.title}</b><br/>
    <i>c3ID: <b>${event.meta.c3eventId}</b>
    </div>
    `;
    return tt;
  }

  private formatBoxEvent(event: CalendarEvent) : string {
    if(event.allDay){
      return `<b>${event.title}</b>` ;
    } else {
      let rLine = '';
      if (event.end && event.end.getTime() -  event.start.getTime()>1800000){
        rLine = `
          ${formatDate(event.start, 'HH:mm', this.locale)} ${event.end?" - "+formatDate(event.end, 'HH:mm', this.locale):""}
          <span class="wrap2"><b>${event.title}</b></span>
        `;
      } else {
        rLine = `
          <b>${event.title}</b>
         `;  
      }
      if (event.meta.location){
        rLine = rLine + `
        <span class="wrap2"><i class="ft-map-pin"></i> ${event.meta.location}</p>
      `;
      }
      return rLine;
    }
  }

  private formatLineEvent(event: CalendarEvent) : string {
    if(event.allDay){
      return `<b>celý den</b> ${
        event.title
     }`; 
    } else {
      return `<b>${formatDate(event.start, 'HH:mm', this.locale)} ${event.end?" - "+formatDate(event.end, 'HH:mm', this.locale):""}</b> ${
        event.title
     }`; 
    }
  }

}