import { NgModule,Injectable, SkipSelf, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

@NgModule({
  // imports: [ 
  //     HttpClient 
  // ]
})

export class EndpointService {

  private _apiEndpoint: string;
  private _authEndpoint: string;
  private _imEndpoint: string;

  private _authUrl: string = "/auth/sign-in";
  private _refreshTokenUrl: string = "/auth/refresh-access-token";

  private _configured = false;
  private _error = 0;
  private _errorText = "";
  private _configError: ConfigError = {};
  
  constructor(
    private httpClient: HttpClient,
    @Optional() @SkipSelf() parentModule?:EndpointService
  ){
    if(parentModule){
      throw new Error(
        'EndpointService is already loaded. Import it in the AppModule only');
    }
    this._apiEndpoint="/c3api"; 
    this._authEndpoint="/c3api"; 
    this._imEndpoint="/im"; 
  }

  ensureInit(): Promise<any> {
    return new Promise((r, e) => {
      this.httpClient.get("./config/config.json")
      .toPromise()
      .then(
        (content : any) => {
          this._configured = true;
          if (content.apiUrl) {
            this._apiEndpoint = content.apiUrl;
          } else {
            this.setError("Chybná konfigurace (apiURL)");
            r(this); 
          }
          if (content.authUrl) {
            this._authEndpoint = content.authUrl;
          } else {
            this.setError("Chybná konfigurace (authURL)");
            r(this); 
          }
          if (content.imUrl) {
            this._imEndpoint = content.imUrl;
          } else {
            this.setError("Chybná konfigurace (imURL)");
            r(this); 
          }
          this._configured = true;
          console.log("Endpont config: ",{
            API: this.apiEndpoint,
            AUTH: this.authEndpoint,
            IM: this.imEndpoint
          });
          r(this);
        },
        (error: any) => {
          this.setError("Chyba při načítání konfigurace");
          this._configError = {
            message: error.message,
            url: error.url,
            httpStatus: error.status,
            httpStatusText: error.statusText
          };
          console.error(this._configError);
          r(this);
        }
      );
    });
//    (reason: any, result: any)=>{}
  }

  private setError(reason: string){
      this._configured = false;
      this._errorText = reason;
      console.error(reason);
  }

  get configured(): boolean {
      return this._configured;
  }

  get errorText(): string {
      return this._errorText;
  }

  get errorTDetails(): ConfigError {
    return this._configError;
  }

  /**
   * API endpoint getter
   */
  get apiEndpoint(): string {
      return this._apiEndpoint;
  }

  /**
   * API endpoint getter
   */
  get authEndpoint(): string {
    return this._authEndpoint;
  }

  /**
   * API endpoint getter
   */
   get imEndpoint(): string {
    return this._imEndpoint;
  }

  get authUrl():string {
  return this.authEndpoint+this._authUrl;
  }
  get refreshTokenUrl():string {
    return this.authEndpoint+this._refreshTokenUrl;
  }



  callApiMethod(
      method: string, options = {}, post = false): Observable<any> {
      if (post){
        return this.httpClient.post(this._apiEndpoint+method, options);        
      } else {
        return this.httpClient.get(this._apiEndpoint+method, options);
      }
  }


}

export interface ConfigError {
  message?: any,
  url?: any,
  httpStatus?: any,
  httpStatusText?: any
}