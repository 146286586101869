import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Google Maps
//import { AgmCoreModule } from "@agm/core";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// Spiner incompatible? - forced
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from "ngx-toastr";

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { MediaMatcherService } from "./shared/services/mediamatcher.service";
import { AuthInterceptor } from "./shared/auth/auth.interceptor";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { NoAuthGuard } from "./shared/auth/noauth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { EndpointService } from "./shared/services/endpoint.service";
import { SocketioService } from "./shared/services/socketio.service";
//import { StyleService } from "./shared/services/style.service";
import { Error404Module } from "./pages/apps/error404/error404.module";
import { CalendarService } from "./shared/services/calendar.service";
import { EtkService } from "./shared/services/etk.service";

//import { StoreModule } from "@ngrx/store";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function loadEndpointConfig(endpointSvc: EndpointService) {
  return () => endpointSvc.ensureInit();
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    //StoreModule.forRoot(fromApp.appReducer),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    // }),
    PerfectScrollbarModule,
    Error404Module,
  ],
  providers: [
    AuthService,
    AuthGuard,
    NoAuthGuard,
    EndpointService,
    SocketioService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadEndpointConfig,
      deps: [EndpointService, HttpClient], multi: true
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    MediaMatcherService,
    //StyleService,
    CalendarService,
    EtkService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    console.log("APP START",new Date());
  }
}
