import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {EndpointService} from "../services/endpoint.service";

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _apiConfig: EndpointService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load messages
     *
     * @private
     */
    private _loadMessages(): Observable<any>
    {
        return this._httpClient.get('api/common/messages');
        //return this._callApiMethod( '/common/messages');

    }

    /**
     * Load navigation data
     *
     * @private
     */
    private _loadNavigation(): Observable<any>
    {
        //return this._httpClient.get('api/common/navigation');
        return this._callApiMethod( '/common/navigation');
    }

    /**
     * Load notifications
     *
     * @private
     */
    private _loadNotifications(): Observable<any>
    {
        return this._httpClient.get('api/common/notifications');
        //return this._callApiMethod( '/common/notifications');
    }

    /**
     * Load shortcuts
     *
     * @private
     */
    private _loadShortcuts(): Observable<any>
    {
        //return this._httpClient.get('api/common/shortcuts');
        return this._callApiMethod( '/common/shortcuts');
    }

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any>
    {
        //return this._httpClient.get('api/common/user');
        return this._callApiMethod( '/common/user');
    }

    /**
     * Load user
     *
     * @private
     */
     private _loadAppConfig(): Observable<any>
     {
         //return this._httpClient.get('api/common/user');
         return this._callApiMethod( '/common/appconfig');
     }
 

    private _callApiMethod(method: string): Observable<any>
    {
        return this._httpClient.get(this._apiConfig.apiEndpoint+method);

    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return forkJoin([
            // // User
            // this._loadUser(),

            // // Navigation data
            // this._loadNavigation(),

            // // Messages
            // this._loadMessages(),


            // // // Notifications
            // this._loadNotifications(),

            // // // Shortcuts
            // // this._loadShortcuts(),

            this._loadAppConfig(),

        ]).pipe(
            map((data) => {
                let d =  {
                    user         : data[0].user,
                    navigation   : {
                        horizontal : data[0].navigation.horizontal,
                        vertical   : data[0].navigation.vertical,
                    },
                    messages     : data[0].messages,
                    notifications: data[0].notifications,
                    shortcuts:     data[0].shortcuts,
                };
                return d;
            })
        );
    }
}
