import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const AUTH_ROUTES: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('../../pages/auth/auth-pages.module').then(m => m.AuthPagesModule)
    }
];
