import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let redirectUrl = state.url;

    if ( redirectUrl === '/sign-out' )
    {
        redirectUrl = '/';
    }

    return this._check(redirectUrl);

  }


  private _check(redirectURL :string): Observable<boolean>{
    return this._authService.isAuthenticated()
    .pipe(
        switchMap((authenticated) => {
            // If the user is not authenticated...
            if ( !authenticated )
            {
                console.log("GUARD AUTH redirect")
              // Redirect to the sign-in page
                 this._router.navigate(['/pages/login'], {queryParams: {redirectURL}});
                 // Prevent the access
                 return of(false);
            }

            // Allow the access
            return of(true);
        })
    );

  }

}
