import { Routes, RouterModule } from '@angular/router';
//import {Error404Module} from '../../pages/apps/error404/error404.module'
import {Error404AppComponent} from '../../pages/apps/error404/error404.component'
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'app/calendar',
    loadChildren: () => import('../../pages/apps/calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    // default page
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'app/etk',
    loadChildren: () => import('../../pages/apps/etk/etk.module').then(m => m.EtkModule)
  },
  {
    path: 'app/chat',
    loadChildren: () => import('../../pages/apps/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'app',
    component: Error404AppComponent,
    //loadChildren: () => import('../../pages/apps/error404/error404.module').then(m => m.Error404Module),
    children :[
        {
          path: 'notfound',
          loadChildren: () => import('../../pages/apps/error404/error404.module').then(m => m.Error404Module)
        },
        {
          path: 'page21',
          loadChildren: () => import('../../pages/apps/error404/error404.module').then(m => m.Error404Module)
        },
        {
          path: 'page23',
          redirectTo: 'notfound'
        },
        {
          path: '**',
          loadChildren: () => import('../../pages/apps/error404/error404.module').then(m => m.Error404Module)
        }
    ]
  }

];
