import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { EndpointService } from '../../shared/services/endpoint.service';
import { User } from '../data/user.interface'
//import { SocketioService} from '../../shared/services/socketio.service';

@Injectable()
export class AuthService {

  // Private
  private _authenticated: boolean = false;

  private authSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._authenticated);

  get authenticated$(): Observable<boolean> {
    return this.authSubject.asObservable();
  }

  private _userdata : User|null;
  
  get userData(): User|null {
    return this._userdata;
  }

  // Access token
  set accessToken(token: string|null) {
    if (token) localStorage.setItem('access_token', token);
    else localStorage.removeItem('access_token');
  }
 
  get accessToken(): string|null {
    return localStorage.getItem('access_token');
  }
 
  get authenticated() : boolean{
    return this._authenticated;
  }

  constructor(
    private _httpClient: HttpClient,
    private _apiConfig: EndpointService,
    //private _socketIoService: SocketioService,

    public router: Router) {

        this._userdata = {
          id: "",
          email: "",
          name: ""
        }

  }

  private setupUser(response:any){
    // Store the access token in the local storage
    this.accessToken = response.access_token;
    this._userdata =  response.user;
    // Set the authenticated flag to true
    this._authenticated = true;
    this.authSubject.next(this._authenticated);
    //this._socketIoService.setupSocketConnection(this.accessToken,this._userdata);

  }

  private destroyUser(){
    //this._socketIoService.disconnect();
    this._authenticated = false;
    // Remove the access token from the local storage
    localStorage.removeItem('access_token');
    this._userdata = null;
    this.authSubject.next(this._authenticated);
  }

  signIn(email: string, password: string): Observable<any> {

    // Throw error, if the user is already logged in
    if ( this._authenticated ) {
        return throwError('User is already logged in.');
    }
    console.debug("signing-in @ "+this._apiConfig.authUrl);

    let credentials = { email, password };
    return this._httpClient.post(this._apiConfig.authUrl, credentials).pipe(
      switchMap((response: any) => {
          if (response.access_token && response.user){
              this.setupUser(response);
          } else {
              // Reset token
              this.destroyUser();
              throw new HttpErrorResponse({
                  status: 502,
                  statusText: 'No token returned',
                  url: this._apiConfig.authUrl
              });
          }
          // Return a new observable with the response
          console.log("auth svc return");
          return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
    signInUsingToken(): Observable<any>
    {
        console.debug("refreshing @ "+this._apiConfig.authUrl);

        // Renew token
        return this._httpClient.post(this._apiConfig.refreshTokenUrl, {
            access_token: this.accessToken
        }).pipe(
            catchError(() => {

                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                if (response.access_token && response.user){
                    this.setupUser(response);
                } else {
                    // Reset token
                    this.destroyUser();
                }

                // Return true
                return of(this._authenticated);
            })
        );
    }

  /**
   * Check the authentication status
   */
   isAuthenticated(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

  /**
   * Sign out
   */
    signOut(): Observable<any>
    {
        // Reset token
        this.destroyUser();

        // Return the observable
        return of(true);
    }
 
}
