import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { CustomizerService } from '../services/customizer.service';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizerComponent implements OnInit, OnDestroy {
  @ViewChild("customizer") customizer!: ElementRef;

  size: string = "";
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    public customizerService: CustomizerService,
    public toastr: ToastrService
  ) {

    this.config = this.configService.templateConf;
    this.isOpen = !this.config.layout.customizer.hidden;

    if (this.config.layout.sidebar.size) {
      this.size = this.config.layout.sidebar.size;
    }
    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.size = this.config.layout.sidebar.size;
      }
      this.cdr.markForCheck();
    })
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {

  }


  resetConfig(){
    // this.toastr.clear();
    // this.toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!');
    // return;
    let _this=this;
    swal.fire({
      title: 'Chcete obnovit nastavení?',
      text: "Operace je nevratná",
      //icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Obnovit',
      cancelButtonText: "Zpět",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    }).then(function (result) {
      if (result.value) {
        _this.customizerService.resetToDefault();
        _this.toastr.info('Nastavení bylo obnoveno.', 'Přizpůsobení vzhedu', {
          positionClass: 'toast-bottom-left'
        });
    
        // swal.fire({
        //   position: 'bottom-start',
        //   //icon: 'success',
        //   //title: 'Nastavení bylo obnoveno',
        //   text: 'Nastavení bylo obnoveno',
        //   showConfirmButton: false,
        //   timer: 1500,
        //   customClass: {
        //     confirmButton: 'btn btn-primary'
        //   },
        //   buttonsStyling: false,
        // });
      }
    });
      
    //this.cdr.markForCheck();
  }

  changeSidebarWidth(value: string) {
    this.size = value;
    this.customizerService.changeSidebarWidth(value);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

    bgImageDisplay(e: any) {
    if (e.target.checked) {
      this.isBgImageDisplay = true;
    } else {
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.customizerService.bgImageDisplay(e);
  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }


}
